import { useEffect, useState } from 'react';
import axios from '../api/axios';
import urls from '../api/urls';
import bag from '../assets/svg/bag.svg';
import bulb from '../assets/svg/bulb.svg';
import rupeeGreen from '../assets/svg/rupee-green.svg';
import rupeeYellow from '../assets/svg/rupee-yellow.svg';
import AreaChart from '../components/AreaChart';
import DataCard from '../components/DataCard';
import LineChart from '../components/LineChart';
import ProductList from '../components/ProductList';

const Overview = () => {
  const [investments, setInvestments] = useState([]);
  const [ideas, setIdeas] = useState([]);

  const getData = async () => {
    await Promise.all([axios.get(urls.service.myInvestments), axios.get(urls.service.ideas)]).then(([res1, res2]) => {
      setInvestments(res1.data.data.investments);
      setIdeas(res2.data.data.ideas);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const cardData = [
    {
      icon: bulb,
      title: 'Total Funded Ideas (TFIs)',
      percentage: '0',
      value: investments.length,
    },
    {
      icon: bag,
      title: 'Total Pre-orders (TPs)',
      percentage: '0',
      value: '0',
      limit: '10000',
    },
    {
      icon: rupeeGreen,
      title: 'Target Gross Profit (TGP)',
      percentage: '0',
      value: '0',
      unit: '₹',
      limit: '20000000',
    },
    {
      icon: rupeeYellow,
      title: 'Customer Aquisition Cost (CAC)',
      percentage: '0',
      value: '0',
      unit: '₹',
      limit: '100000',
    },
  ];

  return (
    <div className="overview">
      <h1>Progress</h1>

      <div className="note">
        Note: Please keep in mind that any graph or reading that appears hazy at first will become clear and dynamic
        once metrics are reached. Please be patient!
      </div>

      <div className="card-wrapper">
        {cardData.map((card) => (
          <DataCard key={card.title} {...card} />
        ))}
      </div>

      <div className="line-chart-wrapper">
        <LineChart
          id="lg1"
          title="No. of daily active users (DAU)"
          date="September 2022"
          stroke="#4339f2"
          bg="#8b80f621"
        />
        <LineChart id="lg4" title="Bounce Rate (BR)" date="September 2022" stroke="#ffbe3c" bg="#ffbe3c14" />
        <LineChart id="lg3" title="Conversions (Cs)" date="September 2022" stroke="#4798e8" bg="#4596e814" />
        <LineChart
          id="lg2"
          title="No. of Pre-ordering Customers (NPCs)"
          date="September 2022"
          stroke="#f64c3b"
          bg="#ef6e6e21"
        />
      </div>

      <div style={{ marginBottom: '40px' }}>
        <AreaChart />
      </div>

      <div className="product-list-wrapper">
        <div className="title">
          <h3>My Investments</h3>
          <p>Overview of KPIs</p>
        </div>
        <ProductList ideas={ideas} investments={investments} />
      </div>
    </div>
  );
};

export default Overview;
