import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios from '../api/axios';
import urls from '../api/urls';
import back from '../assets/svg/arrow-left.svg';
import Button from '../components/inputs/Button';
import LegalDocs from '../components/LegalDocs';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import Overview from '../components/Overview';
import PaymentForm from '../components/PaymentForm';
import ProductInfo from '../components/ProductInfo';
// import Reports from '../components/Reports';
import { TabPanel, Tabs } from '../components/Tabs';
// import Timeline from '../components/Timeline';
import { useGlobalContext } from '../context/context';

const Investment = () => {
  const [value, setValue] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [investment, setInvestment] = useState(null);
  const navigate = useNavigate();
  const {
    state: { investments, ideas },
  } = useGlobalContext();

  const id = useLocation().pathname.split('/').pop();
  const ideaId = investments.find((investment) => investment._id === id)?.idea;
  const ideaName = ideas.find((idea) => idea._id === ideaId)?.name;

  useEffect(() => {
    const _investment = investments.find((investment) => investment._id === id);
    setInvestment(_investment);
  }, [id, investments]);

  const getStamp = async () => {
    setLoading(true);
    await axios
      .get(`${urls.service.getStamp}/${id}`)
      .then((res) => {
        setLoading(false);
        alert('E-stamp has been requested succesfully');
      })
      .catch((err) => {
        setLoading(false);
        alert('Something went wrong');
      });
  };

  const paymentCompleted = investment?.isPaymentCompleted;
  const outstanding = investment?.amount - investment?.amountPaid;

  // const tabList = ['Overview', 'Reports', 'Legal documents', 'Product information', 'Timeline'];
  const tabList = ['Overview', 'Legal documents', 'Product information'];

  if (!investment) {
    return (
      <div className="loader-wrapper">
        <Loader size={40} />
      </div>
    );
  }

  return (
    <div className="investment">
      <div className="heading">
        <div className="back" onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </div>
        <h1>
          {ideaName}{' '}
          <span className={paymentCompleted ? 'complete' : 'incomplete'}>
            ({paymentCompleted ? 'Payment complete' : 'Payment incomplete'})
          </span>
        </h1>
      </div>

      {!paymentCompleted ? (
        <Button variant="outlined" style={{ marginBottom: 40 }} onClick={() => setDisplayModal(true)}>
          Complete payment
        </Button>
      ) : paymentCompleted && !investment.estampComplete ? (
        <Button variant="outlined" style={{ marginBottom: 40 }} onClick={() => getStamp()}>
          {loading ? <Loader /> : 'Get estamp'}
        </Button>
      ) : (
        <></>
      )}

      <div className="note">
        Note: Please keep in mind that any graph or reading that appears hazy at first will become clear and dynamic
        once metrics are reached. Please be patient!
      </div>

      <div className="tab-wrapper">
        <Tabs value={value} setValue={setValue} tabList={tabList} />
      </div>

      <TabPanel value={value} index={0}>
        <Overview />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <Reports />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <LegalDocs />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProductInfo />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <Timeline />
      </TabPanel> */}

      <Modal visible={displayModal} setDisplayModal={setDisplayModal}>
        <PaymentForm id={id} amountPaid={investment.amountPaid} outstanding={outstanding} />
      </Modal>
    </div>
  );
};

export default Investment;
