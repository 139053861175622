import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGlobalContext } from '../context/context';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const { state } = useGlobalContext();
  const { ideas, investments } = state;
  const navigate = useNavigate();

  const getProducts = useCallback(() => {
    const ids = investments.map((investment) => investment._id);
    const ideaIds = investments.map((investment) => investment.idea);
    const names = ideaIds.map((id) => ideas.find((idea) => idea._id === id)?.name);

    const productList = ids.map((id, index) => {
      return {
        id: id,
        name: names[index],
      };
    });

    setProducts(productList);
  }, [ideas, investments]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const getInitials = (string) => {
    const arr = string.split(' ');
    return `${arr[0][0]}${arr[1][0]}`;
  };

  return (
    <div className="product-list">
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: '300px' }}>Investments</th>
            <th style={{ minWidth: '120px' }}>Pre-Orders</th>
            <th style={{ minWidth: '200px' }}>Targeted Gross Profit</th>
            <th style={{ minWidth: '150px' }}>Conversion Rate</th>
            <th style={{ minWidth: '150px' }}>Bounce Rate</th>
            <th style={{ minWidth: '150px' }}>Daily Active Users</th>
            <th style={{ minWidth: '180px' }}>Customer Acq Cost</th>
          </tr>
        </thead>

        {products && (
          <tbody>
            {products?.map((product) => (
              <tr key={product.id} onClick={() => navigate(`/investments/${product.id}`)}>
                <td>
                  <div>
                    <div className="icon">
                      <p>{getInitials(product.name)}</p>
                    </div>
                    <p>{product.name}</p>
                  </div>
                </td>
                <td>0</td>
                <td>
                  <p className="profit">₹0</p>
                </td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>
                  <p className="profit">₹0</p>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default ProductList;
