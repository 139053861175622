import axios from 'axios';
import store from '../utils/store';

const instance = axios.create({
  baseURL: 'https://cspillai-api.com/api/v1',
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getAccessToken();
    if (token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default instance;
