import CountUp from 'react-countup';

const DataCard = ({ icon, title, percentage, value, unit, limit = 10000, blur }) => {
  // let bool = percentage > 0;

  return (
    <div className="data-card">
      <div className="row">
        <img src={icon} alt="" className="icon" />
        <p className="title">{title}</p>
        {/* <p className={`percentage ${bool ? 'positive' : 'negative'}`}>{`${bool ? '+' : ''}${percentage}%`}</p> */}
      </div>

      {value ? (
        <p className={`value ${blur ? 'blur' : ''}`}>
          {unit}
          {value?.toLocaleString()}
        </p>
      ) : (
        <p className={`value ${title !== 'Total Funded Ideas' ? 'blur' : ''}`}>
          {unit}
          <CountUp
            start={title === 'Customer Aquisition Cost' ? 1000000 : 0}
            end={title === 'Customer Aquisition Cost' ? 500 : limit}
            duration={10}
            onEnd={({ start }) => start()}
          />
        </p>
      )}
    </div>
  );
};

export default DataCard;
