import LinearProgress from './LinearProgress';

const LegalDocsChart = ({ documents }) => {
  if (!documents) return;

  return (
    <div className="legal-docs-chart">
      <h3>Legal Documents (Progress)</h3>
      <br />
      <div className="bar-wrapper">
        {Object.keys(documents).map((key, index) => (
          <LinearProgress key={index} progress={documents[key]?.value} doc={key} />
        ))}
      </div>
    </div>
  );
};

export default LegalDocsChart;
