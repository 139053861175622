import { useState } from 'react';
import grid from '../assets/svg/grid.svg';
import list from '../assets/svg/list.svg';
import ProductGrid from '../components/ProductGrid';
import ProductList from '../components/ProductList';

const Investments = () => {
  const [display, setDisplay] = useState('grid');

  const handleClick = () =>
    setDisplay((prev) => {
      if (prev === 'grid') return 'list';
      return 'grid';
    });

  return (
    <div className="investments">
      <div className="heading">
        <h1>Investments</h1>
        <div className="display" onClick={handleClick}>
          <img src={display === 'grid' ? list : grid} alt="" />
        </div>
      </div>

      <div className={`grid${display === 'grid' ? '' : ' hidden'}`}>
        <ProductGrid />
      </div>

      <div className={`list${display === 'list' ? '' : ' hidden'}`}>
        <ProductList />
      </div>
    </div>
  );
};

export default Investments;
