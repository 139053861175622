const LinearProgress = ({ progress, doc }) => {
  let bg;

  if (progress < 25) {
    bg = '#F64C3B';
  } else if (progress >= 25 && progress < 75) {
    bg = '#FFBE3C';
  } else {
    bg = '#22C6AB';
  }

  return (
    <div className="linear-progress">
      <p className="percentage">{progress}%</p>

      <div className="bar">
        <div className="progress" style={{ height: `${progress}%`, backgroundColor: bg }} />
      </div>

      <p className="doc">{doc}</p>
    </div>
  );
};

export default LinearProgress;
