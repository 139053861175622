import { useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import axios from '../api/axios';
import urls from '../api/urls';
import actions from '../context/actions';
import { useGlobalContext } from '../context/context';

const Layout = ({ children }) => {
  const { dispatch } = useGlobalContext();

  useEffect(() => {
    const getData = async () => {
      await Promise.all([
        axios.get(urls.profile.me),
        axios.get(urls.service.myInvestments),
        axios.get(urls.service.ideas),
        axios.get(urls.service.schemas),
      ]).then(([res1, res2, res3, res4]) => {
        dispatch({ type: actions.user.set, payload: res1.data.data.profile });
        dispatch({ type: actions.investments.set, payload: res2.data.data.investments });
        dispatch({ type: actions.ideas.set, payload: res3.data.data.ideas });
        dispatch({ type: actions.schemas.set, payload: res4.data.data.schemas });
      });
    };
    getData();
  }, [dispatch]);

  return (
    <div className="layout">
      <Sidebar />

      <div className="children-wrapper">
        <Header />

        <div className="children">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
