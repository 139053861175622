import { useState } from 'react';
import PersonalInfo from '../components/PersonalInfo';
import { TabPanel, Tabs } from '../components/Tabs';
import UploadPan from '../components/UploadPan';

const Profile = () => {
  const [value, setValue] = useState(0);
  const tabList = ['Personal Information', 'Upload PAN'];

  return (
    <div className="profile">
      <h1>Profile</h1>

      <div className="tab-wrapper">
        <Tabs value={value} setValue={setValue} tabList={tabList} />
      </div>

      <TabPanel value={value} index={0}>
        <PersonalInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UploadPan />
      </TabPanel>
    </div>
  );
};

export default Profile;
