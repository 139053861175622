const MessageBox = ({ label, error, touched, img, value, ...others }) => {
  return (
    <div className="textInput">
      <p className="label">{label}</p>
      <div style={{ marginTop: 5 }}>
        {img && (
          <div className="img">
            <img src={img} alt="" />
          </div>
        )}

        <textarea value={value || ''} {...others} />
      </div>
      <div className="error">{error && touched ? error : ''}</div>
    </div>
  );
};

export default MessageBox;
