const TextInput = ({ label, error, touched, img, value, fullWidth, ...others }) => {
  return (
    <div className="textInput" style={{ width: fullWidth ? '100%' : 'initial' }}>
      <p className="label">{label}</p>
      <div className="input-wrapper">
        {img && (
          <div className="img">
            <img src={img} alt="" />
          </div>
        )}

        <input value={value !== null && value !== undefined ? value : ''} {...others} />
      </div>
      <div className="error">{error && touched ? error : ''}</div>
    </div>
  );
};

export default TextInput;
