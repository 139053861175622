export const Tabs = ({ tabList, value, setValue }) => {
  return (
    <div className="tabs">
      {tabList.map((text, idx) => (
        <Tab key={idx} value={value} setValue={setValue} text={text} idx={idx} />
      ))}
    </div>
  );
};

const Tab = ({ text, idx, value, setValue }) => {
  const bool = value === idx ? true : false;
  return (
    <p
      onClick={() => setValue(idx)}
      style={{
        color: bool ? 'white' : 'black',
        backgroundColor: bool ? '#333d54' : '#f8f9fa',
      }}
    >
      {text}
    </p>
  );
};

export const TabPanel = ({ children, index, value }) => {
  return <div hidden={value !== index}>{value === index && <>{children}</>}</div>;
};
