const tokenVariable = 'ourPortal1-access-token';

const setAccessToken = (token) => localStorage.setItem(tokenVariable, token);
const getAccessToken = () => localStorage.getItem(tokenVariable);
const removeAccessToken = () => localStorage.removeItem(tokenVariable);

const store = {
  setAccessToken,
  getAccessToken,
  removeAccessToken,
};

export default store;
