import { useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';
import email from '../assets/svg/email.svg';
import logo from '../assets/svg/logo.svg';
import padlock from '../assets/svg/padlock.svg';
import store from '../utils/store';

const SignInCard = () => {
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (data, setSubmitting) => {
    axios
      .post(urls.auth.login, data)
      .then((res) => {
        store.removeAccessToken();
        store.setAccessToken(res.data.data.tokens.accessToken);
        navigate('/');
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 404') {
          setErrMsg('Invalid email or password');
        } else {
          setErrMsg(err.message);
        }
        setSubmitting(false);
      });
  };

  return (
    <div className="sign-in-card">
      <div className="header">
        <img src={logo} alt="cs Pillai" />
      </div>
      <div className="body">
        <p className="title">Welcome Back!</p>
        <p className="text">Sign in to continue to csPillai invest to exit dashboard</p>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().trim().required('This field is required').email('E-mail is not valid'),
            password: Yup.string().required('This field is required'),
          })}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ handleSubmit, handleChange, values, touched, errors, isSubmitting, setSubmitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <TextInput
                name="email"
                type="text"
                label="Email"
                img={email}
                value={values.email}
                onChange={handleChange}
                touched={touched.email}
                error={errors.email}
              />
              <div className="space" />
              <TextInput
                name="password"
                type="password"
                label="Password"
                img={padlock}
                value={values.password}
                onChange={handleChange}
                touched={touched.password}
                error={errors.password}
              />

              <div className="forgot-pwd">
                <Button variant="text" type="button" onClick={() => navigate('/forgot-password')}>
                  Forgot Password?
                </Button>
              </div>

              <Button fullwidth size="large" type="submit">
                {isSubmitting ? <Loader /> : 'Sign In'}
              </Button>

              {errMsg && <p className="error">{errMsg}</p>}
            </form>
          )}
        </Formik>
        <div className="space" />
        <p className="signup">
          Don&apos;t have an account yet?{' '}
          <span>
            <a href="https://www.foundingbystakeholders.cspillai.com/register">Sign up</a>
          </span>
        </p>
        <br />

        <p className="power">Powered by csPillai</p>
      </div>
      <div className="footer"></div>
    </div>
  );
};

export default SignInCard;
