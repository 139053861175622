import close from '../assets/svg/close.svg';

const Modal = ({ children, visible, setDisplayModal }) => {
  return (
    <div id="modal" className="modal" style={{ display: visible ? 'grid' : 'none' }}>
      <div id="modalContent" className="modalContent">
        <div className="close" onClick={() => setDisplayModal(false)}>
          <img src={close} alt="close" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
