import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import urls from '../api/urls';
import { useGlobalContext } from '../context/context';

const ProductInfo = () => {
  const [product, setProduct] = useState({});

  const {
    state: { investments, ideas, schemas },
  } = useGlobalContext();
  const id = useLocation().pathname.split('/').pop();

  const getProduct = useCallback(() => {
    const investment = investments.find((investment) => investment._id === id);
    const schemaId = schemas.find((schema) => schema?._id === investment.schema);
    const ideaId = ideas.find((idea) => idea?._id === investment.idea);

    const product = { ...investment, ...schemaId, ...ideaId };
    setProduct(product);
  }, [id, ideas, investments, schemas]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <div className="product-info">
      <div className="img-wrapper">
        <img src={`${urls.images.baseUrl}/${product?.image}`} alt="" />
      </div>

      <div className="headings">
        <h3>Product Name</h3>
        <h3>Problems solved</h3>
        <h3>Investment Plan</h3>
        <h3>Amount Invested</h3>
      </div>

      <div className="content">
        <div>
          <p>{product?.name}</p>
        </div>
        <div>
          {product?.solutions?.map((solution, idx) => (
            <p key={idx}>{solution}</p>
          ))}
        </div>
        <div>
          <p className="plan">{product?.title}</p>
          {product?.points?.map((solution, idx) => (
            <p key={idx}>{solution}</p>
          ))}
        </div>
        <div>
          <p className="amount">₹{product?.amountPaid}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
