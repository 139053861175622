import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../components/inputs/Button';
import MessageBox from '../components/inputs/MessageBox';
import TextInput from '../components/inputs/TextInput';
import Loader from '../components/Loader';
import { useGlobalContext } from '../context/context';

const ContactUs = () => {
  const {
    state: { user },
  } = useGlobalContext();

  const handleSubmit = (value, setSubmitting) => {};

  return (
    <div className="contact">
      <h1>Contact Us</h1>

      <div className="wrapper">
        <div className="form">
          <h3>Leave us a message</h3>
          <Formik
            initialValues={{
              name: `${user?.firstName} ${user?.lastName}`,
              email: user?.email,
              message: '',
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              message: Yup.string().trim().required('This field cannot be empty'),
            })}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
          >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextInput label="Name" value={values.name} readOnly />
                <br />
                <TextInput label="Email" value={values.email} readOnly />
                <br />
                <MessageBox
                  label="Message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  touched={touched.message}
                  error={errors.message}
                  rows={8}
                  cols={window.innerWidth / 24}
                />

                <div className="btn-wrapper">
                  <Button type="submit">{isSubmitting ? <Loader size={25} /> : 'Send'}</Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="info">
          <p className="address">
            <span>Address:</span> 10th Floor, Tower 1, Seawoods Grand Central, Sector 40, Seawoods Railway Station,
            Nerul Node, Mumbai, MH 400706
          </p>
          <p className="phone">
            <span>Phone 1:</span> +91 97029 77719
          </p>
          <p className="phone">
            <span>Phone 2:</span> +91 8850762306
          </p>
          <p className="email">
            <span>Email:</span> mail@cspillai.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
