import actions from './actions';

export const initailState = {
  user: {},
  investments: [],
  ideas: [],
  schemas: [],
};

export const defaultReducer = (state, action) => {
  switch (action.type) {
    case actions.user.set:
      return {
        ...state,
        user: action.payload,
      };
    case actions.user.clear:
      return {
        ...state,
        user: {},
      };
    case actions.investments.set:
      return {
        ...state,
        investments: action.payload,
      };
    case actions.investments.clear:
      return {
        ...state,
        investments: [],
      };
    case actions.ideas.set:
      return {
        ...state,
        ideas: action.payload,
      };
    case actions.ideas.clear:
      return {
        ...state,
        ideas: [],
      };
    case actions.schemas.set:
      return {
        ...state,
        schemas: action.payload,
      };
    case actions.schemas.clear:
      return {
        ...state,
        schemas: [],
      };
    case actions.clear:
      return initailState;
    default:
      return state;
  }
};
