import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import AreaChart from './AreaChart';
import CAGR from './CAGR';
import DataCard from './DataCard';
import LegalDocsChart from './LegalDocsChart';
import LineChart from './LineChart';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';
import bag from '../assets/svg/bag.svg';
import bulb from '../assets/svg/bulb.svg';
import rupeeGreen from '../assets/svg/rupee-green.svg';
import rupeeYellow from '../assets/svg/rupee-yellow.svg';
import { useGlobalContext } from '../context/context';

const Overview = () => {
  const [loading, setLoading] = useState(false);
  const [cagr, setCagr] = useState(null);
  const [metrics, setMetrics] = useState(null);

  const { state } = useGlobalContext();
  const investmentId = useLocation().pathname.split('/').pop();
  const ideaId = state?.investments.find((investment) => investment._id === investmentId).idea;
  const documents = cagr?.kpis.find((item) => item.code === 'LDP').milestone;

  const getData = useCallback(async () => {
    // axios.get(`${urls.cagr.getById}/${investmentId}`)
    // axios.get(`${urls.cagr.getById}/63050806592a29e8dcc48a55`),

    // axios.get(`${urls.kpi.getMetrics}/${ideaId}`),
    // axios.get(`${urls.kpi.getMetrics}/62e12ec0184b35ccd96e23a7`),

    try {
      setLoading(true);
      const [res1, res2] = await Promise.all([
        axios.get(`${urls.cagr.getById}/${investmentId}`),
        axios.get(`${urls.kpi.getMetrics}/${ideaId}`),
      ]);
      setCagr(res1.data.data);
      if (!isEmpty(res2.data.data)) {
        setMetrics(res2.data.data);
      }
    } catch (error) {
      Promise.reject(error);
    } finally {
      setLoading(false);
    }
  }, [ideaId, investmentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const calculateAverage = (arr, propertyName) => {
    const sum = arr?.reduce((accumulator, obj) => accumulator + obj[propertyName], 0);
    return sum / (arr?.length || 1);
  };

  const calculateSum = (arr, propertyName) => {
    const sum = arr?.reduce((accumulator, obj) => accumulator + obj[propertyName], 0);
    return sum;
  };

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const TFI = cagr?.kpis.find((kpi) => kpi.code === 'TFI').value;
  const averageCAC = calculateAverage(metrics?.CustomerAcquisitionCost, 'metricValue');
  const averageTGP = calculateAverage(metrics?.TotalGrossProfit, 'metricValue');
  const totalPreOrders = calculateSum(metrics?.PreOrders, 'metricValue');

  if (loading) {
    return (
      <div className="loader-wrapper">
        <Loader size={50} />
      </div>
    );
  }

  return (
    <div className="overview scroll">
      <div className="card-wrapper">
        <DataCard icon={bulb} title="Total Funded Ideas" code="TFI" value={TFI} limit={1} />
        <DataCard icon={bag} title="Total Pre-orders" code="TPO" value={totalPreOrders} />
        <DataCard icon={rupeeGreen} title="Target Gross Profit" code="TGP" value={averageTGP} unit="₹" />
        <DataCard icon={rupeeYellow} title="Cust Acquisition Cost" code="CAC" value={averageCAC} unit="₹" />
      </div>

      <div className="cagr-wrapper">
        <CAGR id={investmentId} data={cagr} />
      </div>

      <div className="line-chart-wrapper">
        <LineChart
          id="lg1"
          title="No. of daily active users (DAU)"
          stroke="#4339f2"
          bg="#8b80f621"
          values={metrics?.ActiveUsers}
        />
        <LineChart
          id="lg2"
          title="No. of Pre-ordering Customers"
          stroke="#f64c3b"
          bg="#ef6e6e21"
          values={metrics?.PreOrderingCustomers}
        />
        <LineChart
          id="lg3"
          title="Conversions"
          stroke="#4798e8"
          bg="#4596e814"
          values={metrics?.Conversions}
          unit="%"
        />
        <LineChart id="lg4" title="Bounce Rate" stroke="#ffbe3c" bg="#ffbe3c14" values={metrics?.BounceRate} unit="%" />
      </div>

      <div className="area-chart-wrapper">
        <AreaChart values={metrics?.PreOrders} />
        <LegalDocsChart documents={documents} />
      </div>
    </div>
  );
};

export default Overview;
