import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from './inputs/TextInput';
import Button from './inputs/Button';
import Loader from './Loader';
import Select from './inputs/Select';
import { useGlobalContext } from '../context/context';
import { generateYearsArray, months } from '../utils/dates';
import axios from '../api/axios';
import urls from '../api/urls';
import { useState } from 'react';

const UpdateMetrics = () => {
  const {
    state: { ideas },
  } = useGlobalContext();

  const [success, setSuccess] = useState(false);

  const update = async (values) => {
    setSuccess(false);
    const ideaId = ideas.find((idea) => idea.name === values.idea)._id;
    values.ideaId = ideaId;
    delete values.idea;

    await axios
      .post(urls.kpi.updateMetrics, values)
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => Promise.reject(err.response));
  };

  const years = generateYearsArray();

  return (
    <div className="update-metric">
      <Formik
        initialValues={{
          idea: '',
          year: '',
          month: '',
          revenue: '',
          costOfGoods: '',
          customers: '',
          marketSpend: '',
        }}
        validationSchema={Yup.object().shape({
          idea: Yup.string().required('This field is required'),
          year: Yup.string().required('This field is required'),
          month: Yup.string().required('This field is required'),
          revenue: Yup.number().required('This field is required'),
          costOfGoods: Yup.number().required('This field is required'),
          customers: Yup.number().required('This field is required'),
          marketSpend: Yup.number().required('This field is required'),
        })}
        onSubmit={update}
      >
        {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Select
              name="idea"
              label="Idea"
              placeholder="Select idea"
              options={ideas}
              propertyName="name"
              value={values.idea}
              onChange={handleChange}
              touched={touched.idea}
              error={errors.idea}
            />
            <div className="space"></div>
            <div className="row">
              <Select
                name="year"
                label="Year"
                placeholder="Select year"
                options={years}
                value={values.year}
                onChange={handleChange}
                touched={touched.year}
                error={errors.year}
              />
              <div className="space"></div>
              <Select
                name="month"
                label="Month"
                placeholder="Select month"
                options={months}
                value={values.month}
                onChange={handleChange}
                touched={touched.month}
                error={errors.month}
              />
            </div>

            <TextInput
              name="revenue"
              type="number"
              label="Revenue"
              value={values.revenue}
              onChange={handleChange}
              touched={touched.revenue}
              error={errors.revenue}
            />

            <div className="space"></div>

            <TextInput
              name="costOfGoods"
              type="number"
              label="Cost of Goods"
              value={values.costOfGoods}
              onChange={handleChange}
              touched={touched.costOfGoods}
              error={errors.costOfGoods}
            />

            <div className="space"></div>

            <TextInput
              name="marketSpend"
              type="number"
              label="Market spend"
              value={values.marketSpend}
              onChange={handleChange}
              touched={touched.marketSpend}
              error={errors.marketSpend}
            />

            <div className="space"></div>

            <TextInput
              name="customers"
              type="number"
              label="Actual Sales"
              value={values.customers}
              onChange={handleChange}
              touched={touched.customers}
              error={errors.customers}
            />

            <br />
            {success && <p className="success">Metric Updated successfully!</p>}
            <Button type="submit" fullwidth>
              {isSubmitting ? <Loader /> : 'Submit'}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateMetrics;
