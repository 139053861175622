import { useEffect } from 'react';
import Sidebar from './Sidebar';

const Drawer = ({ displayDrawer, setDisplayDrawer }) => {
  useEffect(() => {
    const setFalse = () => {
      setDisplayDrawer(false);
    };

    const handleClick = (e) => {
      if (displayDrawer) {
        const drawer = document.getElementById('drawer');

        if (e.target === drawer) {
          setFalse();
        }
      }
    };

    document.addEventListener('click', (e) => handleClick(e));
    const links = document.querySelectorAll('.sidebar-link');
    links.forEach((link) => link.addEventListener('click', setFalse));

    return () => {
      document.removeEventListener('click', (e) => handleClick(e));
      links.forEach((link) => link.removeEventListener('click', setFalse));
    };
  }, [displayDrawer, setDisplayDrawer]);

  return (
    <div id="drawer" className={`drawer${displayDrawer ? ' opened' : ''}`}>
      <div id="sidebar-wrapper" className="sidebar-wrapper">
        <Sidebar />
      </div>
    </div>
  );
};

export default Drawer;
