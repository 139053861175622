import SignInCard from '../components/SignInCard';

const SignIn = () => {
  return (
    <div className="auth">
      <div className="card-wrapper">
        <SignInCard />
      </div>
    </div>
  );
};

export default SignIn;
