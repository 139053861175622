import { useCallback, useEffect, useState } from 'react';
import Product from './Product';
import add from '../assets/svg/add.svg';
import { useGlobalContext } from '../context/context';

const ProductGrid = () => {
  const [products, setProducts] = useState([]);
  const { state } = useGlobalContext();
  const { ideas, investments, schemas } = state;

  const getProducts = useCallback(() => {
    const ids = investments.map((investment) => investment?._id);
    const schemaIds = investments.map((investment) => investment?.schema);
    const ideaIds = investments.map((investment) => investment?.idea);

    const plans = schemaIds.map((id) => schemas.find((schema) => schema._id === id)?.title);
    const names = ideaIds.map((id) => ideas.find((idea) => idea._id === id)?.name);
    const images = ideaIds.map((id) => ideas.find((idea) => idea._id === id)?.image);

    const productList = ids.map((id, index) => {
      return {
        id: id,
        name: names[index],
        plan: plans[index],
        image: images[index],
      };
    });

    setProducts(productList);
  }, [ideas, investments, schemas]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  if (!products) {
    return (
      <div className="product-grid">
        <a target="_blank" rel="noreferrer" href="https://www.foundingbystakeholders.cspillai.com/" className="fund">
          <img src={add} alt="fund new idea" />
          <p>Fund a New Idea</p>
        </a>
      </div>
    );
  }

  return (
    <div className="product-grid">
      {products?.map((prod) => (
        <Product key={prod.id} {...prod} />
      ))}

      <a target="_blank" rel="noreferrer" href="https://www.foundingbystakeholders.cspillai.com/" className="fund">
        <img src={add} alt="fund new idea" />
        <p>Fund a New Idea</p>
      </a>
    </div>
  );
};

export default ProductGrid;
