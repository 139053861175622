import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';
import { useGlobalContext } from '../context/context';

const PersonalInfo = () => {
  const [uploadedImage, setUploadedImage] = useState(false);
  const { state } = useGlobalContext();

  const handleImageUpload = async (files, setFieldValue) => {
    const form = new FormData();
    form.append('profileImage', files[0]);
    setUploadedImage(true);
    setFieldValue('profileImage', form);
  };

  const handleSubmit = async (data, setSubmitting) => {
    const requests = [
      axios.patch(urls.profile.updateAge, { age: data.age }),
      axios.patch(urls.profile.updatePersonalDetails, {
        firstName: data.firstName,
        lastName: data.lastName,
        organisationName: data.organisationName,
      }),
      axios.patch(urls.profile.updateAddress, {
        line1: data.contact.address.line1,
        line2: data.contact.address.line2,
      }),
      axios.patch(urls.profile.updateContact, {
        country: data.contact.country,
        state: data.contact.state,
        city: data.contact.city,
        zipCode: data.contact.zipCode,
      }),
    ];

    if (uploadedImage) {
      requests.push(axios.patch(urls.profile.updateImage, data.profileImage));
    }

    await Promise.all(requests).then(() => window.location.reload());
  };

  return (
    <div className="personal-info">
      <Formik
        initialValues={state.user}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().trim().required('This field cannot be empty'),
          lastName: Yup.string().trim().required('This field cannot be empty'),
          contact: Yup.object({
            address: Yup.object({
              line1: Yup.string().trim().required('This field cannot be empty').nullable(),
            }),
            country: Yup.string().trim().required('This field cannot be empty').nullable(),
            zipCode: Yup.string().trim().required('This field cannot be empty').nullable(),
            state: Yup.string().trim().required('This field cannot be empty').nullable(),
          }),
        })}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      >
        {({ handleSubmit, handleChange, values, touched, errors, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <TextInput
                name="firstName"
                label="First Name"
                fullWidth
                value={values.firstName}
                onChange={handleChange}
                touched={touched.firstName}
                error={errors.firstName}
              />
              <div className="space" />
              <TextInput
                name="lastName"
                label="Last Name"
                fullWidth
                value={values.lastName}
                onChange={handleChange}
                touched={touched.lastName}
                error={errors.lastName}
              />
              <div className="space" />
              <TextInput
                name="organisationName"
                label="Organisaion Name"
                fullWidth
                value={values.organisationName}
                onChange={handleChange}
                touched={touched.organisationName}
                error={errors.organisationName}
              />
            </div>

            <div className="row">
              <TextInput
                name="age"
                label="Age"
                value={values.age}
                fullWidth
                onChange={handleChange}
                touched={touched.age}
                error={errors.age}
              />
              <div className="space" />
              <TextInput
                name="email"
                label="Email"
                value={values.email}
                fullWidth
                touched={touched.email}
                error={errors.email}
                readOnly
              />
              <div className="space" />
              <TextInput
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                value={values.phoneNumber}
                onChange={handleChange}
                touched={touched.phoneNumber}
                error={errors.phoneNumber}
              />
            </div>

            <div className="row">
              <TextInput
                name="contact.address.line1"
                label="Address (Line 1)"
                fullWidth
                value={values.contact?.address?.line1}
                onChange={handleChange}
                touched={touched.contact?.address?.line1}
                error={errors.contact?.address?.line1}
              />
              <div className="space" />
              <TextInput
                name="contact.address.line2"
                label="Address (Line 2)"
                fullWidth
                value={values.contact?.address?.line2}
                onChange={handleChange}
                touched={touched.contact?.address?.line2}
                error={errors.contact?.address?.line2}
              />
            </div>

            <div className="row">
              <TextInput
                name="contact.country"
                label="Country"
                fullWidth
                value={typeof values.contact?.country !== 'string' ? '' : values.contact?.country}
                onChange={handleChange}
                touched={touched.contact?.country}
                error={errors.contact?.country}
              />
              <div className="space" />
              <TextInput
                name="contact.state"
                label="State"
                value={typeof values.contact?.state !== 'string' ? '' : values.contact?.state}
                fullWidth
                onChange={handleChange}
                touched={touched.contact?.state}
                error={errors.contact?.state}
              />
              <div className="space" />
              <TextInput
                name="contact.zipCode"
                label="Zip Code"
                fullWidth
                value={typeof values.contact?.zipCode !== 'string' ? '' : values.contact?.zipCode}
                onChange={handleChange}
                touched={touched.contact?.zipCode}
                error={errors.contact?.zipCode}
              />
            </div>

            <div className="row gender">
              <TextInput
                name="gender"
                label="Gender"
                fullWidth
                value={values.gender}
                onChange={handleChange}
                touched={touched.gender}
                error={errors.gender}
              />
              <div className="space" />
              <div className="upload">
                <input
                  type="file"
                  name="profileImage"
                  id="profileImage"
                  onChange={(event) => handleImageUpload(event.currentTarget.files, setFieldValue)}
                />
                <label htmlFor="profileImage">Upload Image</label>
              </div>
            </div>

            <div className="btn-wrapper">
              <Button type="submit">{isSubmitting ? <Loader size={25} /> : 'Update'}</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
