import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { defaultReducer, initailState } from './reducer';
import store from '../utils/store';

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(defaultReducer, initailState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const navigate = useNavigate();
  const path = useLocation().pathname.split('/').pop();

  const allowedRoutes = useMemo(() => ['sign-in', 'forgot-password'], []);
  const admins = useMemo(() => ['ahmadalkali.dev@gmail.com', 'suriecp@gmail.com'], []);

  const token = store.getAccessToken();
  useEffect(() => {
    if (!token) {
      if (!allowedRoutes.includes(path)) {
        navigate('/sign-in');
      }
    }

    if (path === 'admin') {
      if (admins.includes(contextValue?.state?.user?.email)) return;

      navigate(-1);
    }
  }, [admins, allowedRoutes, contextValue?.state?.user?.email, navigate, path, token]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default ContextProvider;

export const useGlobalContext = () => useContext(Context);
