import { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';
import email from '../assets/svg/email.svg';
import logo from '../assets/svg/logo.svg';

const ForgotPasswordCard = () => {
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (data, setSubmitting) => {
    setErrMsg('');
    setSuccess(false);
    axios
      .post(urls.auth.forgotPassword, data)
      .then((res) => {
        setSuccess(true);
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        setErrMsg(err.response?.data?.message);
      });
  };
  return (
    <div className="sign-in-card">
      <div className="header">
        <img src={logo} alt="cs Pillai" />
      </div>
      <div className="body">
        <p className="title">Reset Password</p>
        <p className="text">Enter your email, and instructions will be sent to you</p>
        <br />

        {success && <p className="success">Reset link sent successfully, please check you email</p>}

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().trim().required('This field is required').email('E-mail is not valid'),
          })}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <TextInput
                name="email"
                type="text"
                label="Email"
                img={email}
                value={values.email}
                onChange={handleChange}
                touched={touched.email}
                error={errors.email}
              />
              <br />

              <Button fullwidth size="large" type="submit">
                {isSubmitting ? <Loader /> : 'Reset'}
              </Button>
              {errMsg && <p className="error">{errMsg}</p>}
            </form>
          )}
        </Formik>
        <br />

        <p className="forgot-pwd">
          <Link to="/sign-in">Login</Link>
        </p>

        <p className="power">Powered by csPillai</p>
      </div>
      <div className="footer"></div>
    </div>
  );
};

export default ForgotPasswordCard;
