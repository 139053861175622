import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';

const PaymentForm = ({ id, amountPaid, outstanding }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const completePayment = async ({ amount }) => {
    await axios
      .post(`${urls.service.addFund}/${id}`, { amount })
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };

  if (success) {
    return (
      <div className="payment-form">
        <h2>Payment is being processed...</h2>

        <p>A payment link will be sent to your mail</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="payment-form">
        <h2>Error</h2>

        <p>Something went wrong</p>
      </div>
    );
  }

  return (
    <div className="payment-form">
      <h2>Complete Payment</h2>

      <p className="note">
        <span>Note: </span>You can keep paying in installments, or you can pay completely
      </p>

      <div className="amount">
        <p>Amount paid</p>
        <p className="number">₹{amountPaid}</p>
      </div>

      <div className="amount">
        <p>Amount outstanding</p>
        <p className="number">₹{outstanding}</p>
      </div>

      <br />

      <Formik
        initialValues={{
          amount: '',
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number().required('This field is required'),
        })}
        onSubmit={completePayment}
      >
        {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <TextInput
              name="amount"
              type="number"
              label="Amount"
              value={values.amount}
              onChange={handleChange}
              touched={touched.amount}
              error={errors.amount}
            />
            <br />
            <Button type="submit" fullwidth>
              {isSubmitting ? <Loader /> : 'Pay now'}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentForm;
