import { useNavigate } from 'react-router';
import urls from '../api/urls';
import product from '../assets/svg/product.svg';

const Product = ({ id, image, name, plan }) => {
  const navigate = useNavigate();

  return (
    <div className="product" onClick={() => navigate(`/investments/${id}`)}>
      <div className="img-wrapper">
        <img src={`${urls.images.baseUrl}/${image}`} alt="" />
      </div>

      <div className="info">
        <div>
          <h3>{name}</h3>
          <p className="plan">{plan}</p>
        </div>
        <div className="icon">
          <img src={product} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Product;
