import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from './inputs/Button';
import Select from './inputs/Select';
import Loader from './Loader';
import TextInput from '../components/inputs/TextInput';
// import { useGlobalContext } from '../context/context';

const UpdateKPIForm = ({ innerRef, success, kpi, onSubmit }) => {
  // const { state } = useGlobalContext();
  // const { ideas } = state;

  // const ideaNames = ideas.map((idea) => ({
  //   name: idea.name,
  //   value: idea._id,
  // }));

  return (
    <Formik
      enableReinitialize
      initialValues={{
        idea: '',
        milestone: '',
        value: kpi?.value,
      }}
      innerRef={innerRef}
      validationSchema={Yup.object().shape({
        // idea: Yup.string().required('This field is required'),
        milestone: Yup.string().when({
          is: () => kpi?.code === 'LDP',
          then: Yup.string().required('This field is required'),
          otherwise: Yup.string(),
        }),
        value: Yup.number().required('This field is required'),
      })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          {success ? (
            <p className="success">KPI updated successfully</p>
          ) : (
            <div className="form-wrapper">
              {/* <Select
                name="idea"
                label="Ideas"
                placeholder="Select idea"
                options={ideaNames}
                value={values.idea}
                onChange={handleChange}
                touched={touched.idea}
                error={errors.idea}
              /> */}

              {kpi?.code === 'LDP' && (
                <Select
                  name="milestone"
                  label="Milestones"
                  placeholder="Select milestone"
                  options={milestones}
                  value={values.milestone}
                  onChange={handleChange}
                  touched={touched.milestone}
                  error={errors.milestone}
                />
              )}

              <TextInput
                name="value"
                type="number"
                label="Value"
                value={values.value}
                onChange={handleChange}
                touched={touched.value}
                error={errors.value}
              />

              <Button type="submit" fullwidth>
                {isSubmitting ? <Loader /> : 'Update KPI'}
              </Button>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default UpdateKPIForm;

const milestones = [
  { name: 'SDD', value: 'SDD' },
  { name: 'DPIIT', value: 'DPIIT' },
  { name: 'NPA', value: 'NPA' },
  { name: 'CN', value: 'CN' },
];
