import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import urls from '../api/urls';
import defaultAvatar from '../assets/png/avatar.png';
import logo from '../assets/svg/logo.svg';
import logout from '../assets/svg/logout.svg';
import carat from '../assets/svg/sidebar/carat.svg';
// import contact from '../assets/svg/sidebar/contact.svg';
// import inbox from '../assets/svg/sidebar/inbox.svg';
import investments from '../assets/svg/sidebar/investments.svg';
// import overview from '../assets/svg/sidebar/overview.svg';
import profile from '../assets/svg/sidebar/profile.svg';
import { useGlobalContext } from '../context/context';
import store from '../utils/store';

const links = [
  {
    id: 0,
    name: 'Investments',
    to: '/',
    img: investments,
  },
  // {
  //   id: 1,
  //   name: 'Progress',
  //   to: '/progress',
  //   img: overview,
  // },
  {
    id: 1,
    name: 'Profile',
    to: '/profile',
    img: profile,
  },
  // {
  //   id: 3,
  //   name: 'Contact us',
  //   to: '/contact-us',
  //   img: contact,
  // },
  {
    id: 2,
    name: 'Admin',
    to: '/admin',
    img: investments,
  },
];

const admins = ['ahmadalkali.dev@gmail.com', 'suriecp@gmail.com'];

const Sidebar = () => {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useGlobalContext();

  const path = useLocation().pathname;

  useEffect(() => {
    const index = getPathIndex(path);
    setActive(index);
  }, [path]);

  const getPathIndex = (name) => {
    if (name === '/') return 0;
    // if (name === '/investments') return 1;
    if (name === '/profile') return 1;
    // if (name === '/contact-us') return 3;
    if (name === '/admin') return 2;
  };

  const handleClick = (link, index) => {
    setActive(index);
    navigate(link);
  };

  const logOut = () => {
    store.removeAccessToken();
    dispatch({ type: 'CLEAR_USER' });
    navigate('/sign-in');
  };

  return (
    <div className="sidebar">
      <div className="header">
        <img src={logo} alt="cs pillai" />
      </div>

      <div className="profile">
        {user.profileImage ? (
          <img src={`${urls.images.baseUrl}/${user.profileImage}`} alt="" className="avatar" />
        ) : (
          <div className="default-avatar">
            <img src={defaultAvatar} alt="" />
          </div>
        )}
        {user.firstName && <p className="name">{`${user.firstName} ${user.lastName}`}</p>}
      </div>

      <div id="sidebar-links" className="links">
        {links
          .filter((item) => item.id < 3 || admins.includes(user?.email))
          .map((link, idx) => (
            <div
              className={`sidebar-link ${active === idx ? 'active' : ''}`}
              key={link.name}
              onClick={() => handleClick(link.to, idx)}
            >
              <img src={link.img} alt={link.name} className="icon" />
              <p>{link.name}</p>
              <img src={carat} alt="" className="carat" />
            </div>
          ))}
      </div>

      <div className="logout">
        <div className="sidebar-link" onClick={logOut}>
          <img src={logout} alt="logout" className="icon" />
          <p>Log out</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
