import { Route, Routes, useLocation } from 'react-router';
import Layout from './components/Layout';
import { appRoutes } from './routes/routes';

const renderRoutes = () => {
  return (
    <Routes>
      {appRoutes.map((route, index) => (
        <Route key={index} path={route.to} element={route.element} />
      ))}
    </Routes>
  );
};

const App = () => {
  const path = useLocation().pathname.split('/').pop();
  const urls = ['sign-in', 'forgot-password'];

  if (urls.includes(path)) return renderRoutes();

  return <Layout>{renderRoutes()}</Layout>;
};

export default App;
