import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import axios from '../api/axios';
import urls from '../api/urls';
import pic from '../assets/svg/img.svg';
import Button from '../components/inputs/Button';
import Loader from '../components/Loader';
import { useGlobalContext } from '../context/context';

const UploadPan = () => {
  const [PANCard, setPANCard] = useState({
    investorID: '',
    frontUrl: '',
    backUrl: '',
  });
  const [formState, setFormState] = useState({
    investorID: '',
    front: '',
    back: '',
  });
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const {
    state: { user },
  } = useGlobalContext();
  const investorID = user?._id;

  useEffect(() => {
    const getPANCard = async () => {
      axios.get(urls.uploadPAN, { params: { investorID } }).then((res) => setPANCard(res.data.data.result[0]));
    };
    getPANCard();
  }, [investorID]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessMsg('');
    }, 5000);
  }, [successMsg]);

  const handleClick = async () => {
    if (!formState.front && !formState.back) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('investorID', investorID);
    formData.append('front', formState.front);
    formData.append('back', formState.back);

    axios.post(urls.uploadPAN, formData).then((res) => {
      setSuccessMsg('Uploaded successfully');
      setIsLoading(false);
    });
  };

  return (
    <div className="upload-pan">
      <div className="card-wrapper">
        <img src={`${urls.images.baseUrl}/${PANCard?.frontUrl}`} alt="" />
        <div className="space" />
        <img src={`${urls.images.baseUrl}/${PANCard?.backUrl}`} alt="" />
      </div>

      <div className="upload-wrapper">
        <div className="upload">
          <p>Upload PAN Card Front</p>
          <Dropzone
            onDrop={(files) => {
              const reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onloadend = () => setFrontPreview(reader.result);
              setFormState((prev) => ({
                ...prev,
                front: files[0],
              }));
            }}
            accept={{ 'image/*': [] }}
            multiple={false}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {frontPreview ? (
                  <img src={frontPreview} alt="" className="preview" />
                ) : (
                  <>
                    <img src={pic} alt="" className="pic" />
                    {isDragActive ? (
                      <p>Drop the image here ...</p>
                    ) : (
                      <p>Click and select from directory or drag and drop file</p>
                    )}
                  </>
                )}
              </div>
            )}
          </Dropzone>
        </div>

        <div className="space" />

        <div className="upload">
          <p>Upload PAN Card Back</p>
          <Dropzone
            onDrop={(files) => {
              const reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onloadend = () => setBackPreview(reader.result);
              setFormState((prev) => ({
                ...prev,
                back: files[0],
              }));
            }}
            accept={{ 'image/*': [] }}
            multiple={false}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {backPreview ? (
                  <img src={backPreview} alt="" className="preview" />
                ) : (
                  <>
                    <img src={pic} alt="" className="pic" />
                    {isDragActive ? (
                      <p>Drop the image here ...</p>
                    ) : (
                      <p>Click and select from directory or drag and drop file</p>
                    )}
                  </>
                )}
              </div>
            )}
          </Dropzone>
        </div>
      </div>

      <div className="btn-wrapper">
        <Button onClick={handleClick}>{isLoading ? <Loader size={25} /> : 'Upload'}</Button>
      </div>
      {successMsg && <p className="success">{successMsg}</p>}
    </div>
  );
};

export default UploadPan;
