import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useLocation } from 'react-router-dom';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';
import pdf from '../assets/svg/pdf.svg';

const LegalDocs = () => {
  const [doc, setDoc] = useState();
  const [numPages, setNumPages] = useState(null);
  const [documents, setDocuments] = useState(null);

  const id = useLocation().pathname.split('/').pop();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (documents) {
      setDoc(documents['sba']);
    }
  }, [documents]);

  useEffect(() => {
    const getData = async () => {
      await axios.get(`${urls.service.documents}/${id}/documents`).then((res) => {
        setDocuments(res.data.data);
      });
    };

    getData();
  }, [id]);

  let bg;

  const getBG = (percentage) => {
    if (percentage < 25) {
      bg = '#F64C3B';
    } else if (percentage >= 25 && percentage < 75) {
      bg = '#FFBE3C';
    } else {
      bg = '#22C6AB';
    }

    return bg;
  };

  if (!documents) {
    return (
      <div className="loader-wrapper">
        <Loader size={50} />
      </div>
    );
  }

  return (
    <div className="legal-docs">
      <div className="doc-list">
        {Object.keys(documents).map((key, index) => (
          <div className="doc" key={index} onClick={() => setDoc(documents[key])}>
            <div className="icon">
              <img src={pdf} alt="" />
            </div>
            <div className="details">
              <div className="name-wrapper">
                <p className="name">{key}</p>{' '}
                <p className="percentage">
                  {documents[key]?.esign?.length === 1 ? '50' : documents[key]?.esign?.length === 2 ? '100' : '0'}%
                </p>
              </div>
              <div className="bar">
                <div
                  className="progress"
                  style={{
                    width: `${
                      documents[key]?.esign?.length === 1 ? '50' : documents[key]?.esign?.length === 2 ? '100' : '0'
                    }%`,
                    backgroundColor: getBG(documents[key]?.percentage),
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="doc-expanded">
        {doc?.esign ? (
          <>
            <div className="link-wrapper">
              <a target="_blank" rel="noreferrer" href={doc?.esign[0].downloadUrl}>
                Download
              </a>
            </div>
            <Document file={doc?.esign[0].downloadUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <div className="pdf-page" key={index + 1}>
                  <Page pageNumber={index + 1} />
                  <p className="page-number">
                    Page {index + 1} of {numPages}
                  </p>
                </div>
              ))}
            </Document>
          </>
        ) : (
          <div className="nothing">
            <p>Document not available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LegalDocs;
