import { useEffect, useState } from 'react';
import { LineChart as LC, Line, ResponsiveContainer, Tooltip } from 'recharts';

const LineChart = ({ id, title, stroke, bg, values, blur, unit }) => {
  const [data, setData] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (Array.isArray(values)) {
      setData(values);
    }
  }, [values]);

  useEffect(() => {
    const container = document.getElementById(id);
    container.style.backgroundColor = bg;
  }, [bg, id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsHidden((prev) => !prev);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  // const value = 0;
  // const bool = value > 0;

  return (
    <div className="line-chart">
      <div className="details">
        {/* <p className="value">0</p>
        <p className={`percentage ${bool ? 'positive' : 'negative'}`}>{`${bool ? '+' : ''}${value}%`}</p> */}
      </div>

      <ResponsiveContainer id={id} width="100%" height="100%" className={data.length ? '' : 'blur'}>
        <LC width={300} height={100} data={data.length ? data : source} margin={{ top: 50 }}>
          <Line type="monotone" dataKey="metricValue" stroke={stroke} strokeWidth={2} dot={false} hide={isHidden} />
          {data.length && <Tooltip content={<CustomTooltip unit={unit} />} />}
        </LC>
      </ResponsiveContainer>

      <div className="info">
        <p className="title">{title}</p>
      </div>
    </div>
  );
};

export default LineChart;

const CustomTooltip = ({ active, payload, unit }) => {
  if (active) {
    const month = payload[0].payload.metricMonth;
    const year = payload[0].payload.metricYear;
    return (
      <div className="custom-tooltip">
        <p className="label">{`${month}, ${year}: ${payload[0].value}${unit ? unit : ''}`}</p>
      </div>
    );
  }

  return null;
};

const source = [
  {
    name: 'A',
    metricValue: 0,
  },
  {
    name: 'B',
    metricValue: 100,
  },
  {
    name: 'C',
    metricValue: 95,
  },
  {
    name: 'D',
    metricValue: 150,
  },
  {
    name: 'E',
    metricValue: 150,
  },
  {
    name: 'F',
    metricValue: 190,
  },
  {
    name: 'G',
    metricValue: 160,
  },
  {
    name: 'H',
    metricValue: 240,
  },
  {
    name: 'I',
    metricValue: 245,
  },
  {
    name: 'J',
    metricValue: 300,
  },
];
