const urls = {
  auth: {
    login: '/investors/login',
    forgotPassword: '/investors/request-password-reset',
  },
  profile: {
    me: '/investors/me',
    updatePersonalDetails: 'investors/profile/update-personal-details',
    updateAge: '/investors/profile/update-age',
    updateContact: '/investors/profile/update-contact',
    updateAddress: '/investors/profile/update-address',
    updateImage: '/investors/profile/change-image',
  },
  service: {
    schemas: '/services/invest2exit/schemas',
    ideas: '/services/invest2exit/ideas',
    investments: '/services/invest2exit/investments',
    myInvestments: '/services/invest2exit/investments/me',
    documents: '/services/invest2exit/investments/me',
    addFund: '/services/invest2exit/add-fund',
    verify: '/services/invest2exit/investments/update',
    getStamp: '/services/invest2exit/sba',
  },
  reports: {
    baseUrl: '/report/by-investor',
    byInvestorID: '/report/by-investor',
    byProductID: '/report/by-product',
    markRead: '/report/mark-read',
  },
  images: {
    baseUrl: 'https://cspillai-api.com/api/v1/file-streams',
  },
  cagr: {
    getById: '/cagr',
    update: '/cagr/update',
  },
  progress: {
    baseUrl: '/progress',
    extension: '/progress/extension',
    markComplete: '/progress/milestone',
  },
  uploadPAN: 'uploadPAN',
  getTokens: '/sponsorships',
  refunds: {
    getAll: '/admin/refunds',
    approve: '/admin/approve-refund',
  },
  kpi: {
    getAll: '/kpi',
    getByInvestmentID: '/kpi',
    update: '/kpi/update',
    getMetrics: '/kpi/metrics',
    updateMetrics: '/kpi/metrics/values',
  },
};

export default urls;
