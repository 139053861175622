import { useState } from 'react';
import KPIs from '../components/KPIs';
import Refunds from '../components/Refunds';
import { TabPanel, Tabs } from '../components/Tabs';
import Tokens from '../components/Tokens';
import UpdateMetrics from '../components/UpdateMetrics';

const Admin = () => {
  const [value, setValue] = useState(0);
  const tabList = ['KPIs', 'Tokens', 'Refunds', 'Update Metrics'];

  return (
    <div className="admin">
      <h1>Admin Panel</h1>

      <div className="tab-wrapper">
        <Tabs value={value} setValue={setValue} tabList={tabList} />
      </div>

      <TabPanel value={value} index={0}>
        <KPIs />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Tokens />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Refunds />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <UpdateMetrics />
      </TabPanel>
    </div>
  );
};

export default Admin;
