import { useCallback, useEffect, useRef, useState } from 'react';
import Select from './inputs/Select';
import Loader from './Loader';
import Modal from './Modal';
import UpdateKPIForm from './UpdateKPIForm';
import axios from '../api/axios';
import urls from '../api/urls';

const KPIs = () => {
  const [investments, setInvestments] = useState(null);
  const [investment, setInvestment] = useState(null);
  const [investmentId, setInvestmentId] = useState('');
  const [investmentKpis, setInvestmentKpis] = useState(null);
  const [kpi, setKpi] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);

  const formikRef = useRef();

  useEffect(() => {
    const getInvestments = async () => {
      await axios.get(urls.service.investments).then((res) => {
        setInvestments(res.data.data);
      });
    };
    getInvestments();
  }, []);

  const getKPIs = useCallback(async () => {
    if (!investmentId) return;

    setLoading(true);

    const investment = investments.find((item) => item.investmentId === investmentId);
    setInvestment(investment);

    setInvestmentKpis(null);
    await axios
      .get(`${urls.kpi.getAll}/${investmentId}`)
      .then((res) => {
        setInvestmentKpis(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [investmentId, investments]);

  useEffect(() => {
    getKPIs();
  }, [getKPIs, investmentId]);

  const editedInvestments = investments?.map((item) => ({
    ...item,
    value: item.investmentId,
    name: `${item.investor?.firstName} ${item.investor?.lastName} - ${item.idea?.name}`,
  }));

  const handleClick = (kpi) => {
    if (kpi.code !== 'LDP') return;
    setSuccess(false);
    formikRef.current?.resetForm();
    setKpi(kpi);
    setDisplayModal(true);
  };

  const updateKpi = async ({ idea, milestone, value }) => {
    let payload;
    if (kpi.code === 'LDP') {
      payload = {
        investmentId,
        milestone,
        value,
      };
    } else {
      payload = {
        ideaId: investment.idea._id,
        kpi: kpi.code,
        milestone,
        value,
      };
    }
    await axios.post(urls.kpi.update, payload).then(() => {
      setSuccess(true);
      getKPIs();
    });
  };

  if (!investments) {
    return (
      <div className="loader-wrapper">
        <Loader size={50} />
      </div>
    );
  }

  if (!investments.length) {
    return (
      <div className="tokens">
        <p>No tokens to display</p>
      </div>
    );
  }

  return (
    <div className="kpis">
      <div className="select-wrapper">
        <Select
          label="Investments"
          placeholder="Select investment"
          options={editedInvestments}
          onChange={(e) => setInvestmentId(e.target.value)}
        />
      </div>

      {loading && (
        <div className="loader-wrapper">
          <Loader size={30} />
        </div>
      )}

      {investmentKpis && (
        <>
          <p className="detail">
            <span>CAGR:</span> {investmentKpis.cagr.value}/59%
          </p>
          <p className="detail">
            <span>Investment amount: </span>
            {investment.amount}
          </p>
          <p className="detail">
            <span>Amount Paid: </span>
            {investment.amountPaid}
          </p>
          <p className={investment.isPaymentCompleted ? 'complete' : 'incomplete'}>
            {investment.isPaymentCompleted ? 'Payment complete' : 'Payment incomplete'}
          </p>

          <div className="grid">
            {investmentKpis.kpis
              ?.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))
              .map((kpi) => (
                <div key={kpi._id} className="kpi" onClick={() => handleClick(kpi)}>
                  <p>
                    <span>KPI:</span> {kpi.name}
                  </p>
                  <p>
                    <span>Code:</span> {kpi.code}
                  </p>
                  <p>
                    <span>Percentage:</span> {kpi.rate}%
                  </p>
                  <p>
                    <span>Value:</span> {kpi.value}
                  </p>
                </div>
              ))}
          </div>
        </>
      )}

      <Modal visible={displayModal} setDisplayModal={setDisplayModal}>
        <UpdateKPIForm innerRef={formikRef} success={success} kpi={kpi} onSubmit={updateKpi} />
      </Modal>
    </div>
  );
};

export default KPIs;
