import { useState } from 'react';
import Drawer from './Drawer';
import menu from '../assets/svg/hamburger.svg';
import logo from '../assets/svg/logo.svg';

const Header = () => {
  const [displayDrawer, setDisplayDrawer] = useState(false);

  return (
    <div className="header-component">
      {!displayDrawer ? <img src={logo} alt="cs pillai" /> : <div style={{ visibility: 'hidden' }}>.</div>}

      <div id="menu" className="menu-wrapper" onClick={() => setDisplayDrawer((prev) => !prev)}>
        <img src={menu} alt="menu" />
      </div>

      <Drawer displayDrawer={displayDrawer} setDisplayDrawer={setDisplayDrawer} />
    </div>
  );
};

export default Header;
