import { useState } from 'react';
import Button from './inputs/Button';
import Loader from './Loader';
import Modal from './Modal';
import axios from '../api/axios';
import urls from '../api/urls';
import greenTick from '../assets/svg/green-tick.svg';
import needle from '../assets/svg/needle.svg';
import { useGlobalContext } from '../context/context';
import { getDate, getDaysElapsed, getShortDate, getStartDate } from '../utils/dates';

const TOTAL_DAYS = 540;
const TOTAL_ANGLE = 270;

const CAGR = ({ data }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const {
    state: { user },
  } = useGlobalContext();

  const ideaCompleted = data?.cagr.percentage >= 59;
  const completedKpis = data?.kpis.reduce((result, item) => {
    if (item.code === 'LDP' && item.milestone) {
      const milestones = Object.entries(item.milestone);
      const milestoneWithDateActivated = milestones.find(([name, ms]) => ms.dateCompleted);
      if (milestoneWithDateActivated) {
        const [name, milestone] = milestoneWithDateActivated;
        milestone.dateActivated = milestone.dateCompleted;
        milestone.name = name;
        result.push(milestone);
      }
    } else if (item.dateActivated !== undefined) {
      result.push(item);
    }

    return result;
  }, []);

  const sortedArray = completedKpis?.sort((a, b) => {
    const dateA = new Date(a.dateActivated);
    const dateB = new Date(b.dateActivated);
    return dateA - dateB;
  });

  const currentAngle =
    ((getStartDate(data?.status.startDate) + getDaysElapsed(data?.status.startDate)) / TOTAL_DAYS) * TOTAL_ANGLE || 0;
  let guageProgress = ideaCompleted ? TOTAL_ANGLE : currentAngle;

  const lines = new Array(90).fill('').map((i, index) => index + 1);
  const numbers = new Array(19).fill('').map((i, index) => {
    if (String(index).length === 1) return `0${index}`;
    return `${index}`;
  });

  const dates = new Array(18).fill('').map((i, index) => {
    const initialDate = data?.cagr.startDate ? new Date(data?.cagr.startDate) : new Date('9/1/2022');
    const date = new Date(initialDate.setMonth(initialDate.getMonth() + index));
    return getShortDate(date);
  });

  const deadlinePassed = (d1, d2) => {
    const date1 = new Date(d1).getTime();
    const date2 = new Date(d2).getTime();

    if (date1 < date2) {
      return true;
    }

    return false;
  };

  const extend = async (vote) => {
    const hasVoted = data?.cagr.votes.find((item) => item.investorID === user?._id);
    if (hasVoted) return;

    setIsLoading(true);
    axios
      .patch(urls.cagr.update, { ideaID: data?.cagr.idea, invetorID: user?._id, extend: vote })
      .then((res) => {
        setDisplayModal(false);
        setMessage('Vote cast successfully');
      })
      .catch((err) => {
        setDisplayModal(false);
      });
  };

  const today = new Date();
  let failed = false;

  if (deadlinePassed(data?.cagr.dueDate, String(today))) {
    failed = true;
    if (data) data.cagr.percentage = 0;
    guageProgress = 0;
  }

  return (
    <div className="cagr">
      <div className="meter-wrapper">
        <h3>CAGR Indicator</h3>

        <div className="meter">
          <div className="guage"></div>
          <img
            src={needle}
            alt=""
            className="needle"
            style={{
              transform: `translate(-50%, calc(-50% - 40px)) rotate(calc(225deg + ${guageProgress}deg))`,
            }}
          />
          <div className="percentage">{parseFloat(data?.cagr?.percentage.toFixed(1)) || 0}%</div>
          {lines.map((line, index) => (
            <div key={line} className={`line line-${index}`}></div>
          ))}
          <div className="line-cover"></div>
          <div className="inverse"></div>
          <div className="numbers">
            {numbers.map((number, index) => (
              <span key={number} className={`number number-${index}`}>
                {number}
              </span>
            ))}
          </div>
          <div className="dates">
            {dates.map((date, index) => (
              <span key={date} className={`date date-${index}`}>
                {date}
              </span>
            ))}
          </div>
        </div>

        {failed && (
          <div className="btn-wrapper">
            <Button size="small" onClick={() => setDisplayModal(true)}>
              Extend Maturity Date
            </Button>
            <Button size="small">Votes: {data?.cagr.votes.length || 0}</Button>
          </div>
        )}

        {message && <p className="success">{message}</p>}
      </div>

      <div className="activity-wrapper">
        <h3>Activities Completed</h3>
        {sortedArray?.map((kpi, index) => (
          <div key={kpi._id || index} className="activity">
            <div>
              <img src={greenTick} alt="" />

              <p className="title">
                <strong>KPI / Milestone :</strong> {kpi.name}
              </p>
            </div>

            {kpi.dateActivated && (
              <p>
                <strong style={{ color: '#22c6ab' }}>Completed date: </strong>
                {getDate(kpi.dateActivated)}
              </p>
            )}
          </div>
        ))}
      </div>

      <Modal visible={displayModal} setDisplayModal={setDisplayModal}>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <p>Do you want to extend the maturity of the investment by 3 months?</p>

            <div className="buttons">
              <Button variant="contained" onClick={() => extend(true)}>
                Yes
              </Button>
              <div className="space" />
              <Button variant="contained" onClick={() => extend(false)}>
                No
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CAGR;
