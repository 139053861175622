const actions = {
  user: {
    set: 'SET_USER',
    clear: 'CLEAR_USER',
  },
  investments: {
    set: 'SET_INVESTMENTS',
    clear: 'CLEAR_INVESTMENTS',
  },
  ideas: {
    set: 'SET_IDEAS',
    clear: 'CLEAR_IDEAS',
  },
  schemas: {
    set: 'SET_SCHEMAS',
    clear: 'CLEAR_SCHEMAS',
  },
  clear: 'CLEAR_STATE',
};

export default actions;
