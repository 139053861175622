import React, { useEffect, useState } from 'react';
import Button from './inputs/Button';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';

const Tokens = () => {
  const [tokens, setTokens] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    getTokens();
  }, []);

  const getTokens = async () => {
    await axios.get(urls.getTokens).then((res) => setTokens(res.data));
  };

  const handleClick = async (index, paymentReference, service, amount) => {
    setIsLoading(index);
    if (service.toLowerCase() === 'pillais.group') {
      try {
        await axios.post('https://api.pillais.group/v1/payments/confirm', { token: paymentReference });
        await axios.post('https://cspillai-api.com/api/v1/services/invest2exit/sponsorship/confirm', {
          ref: paymentReference,
        });
        await getTokens();
      } catch (error) {
        Promise.reject(error);
      } finally {
        setIsLoading(null);
      }
      return;
    }

    await axios
      .post(urls.service.verify, { paymentReference, amount })
      .then(() => {
        setIsLoading(null);
        getTokens();
      })
      .catch(() => setIsLoading(false));
  };

  if (!tokens) {
    return (
      <div className="loader-wrapper">
        <Loader size={50} />
      </div>
    );
  }

  if (!tokens.length) {
    return (
      <div className="tokens">
        <p>No tokens to display</p>
      </div>
    );
  }

  return (
    <div className="tokens">
      {tokens.map((item, index) => (
        <div key={item._id} className="token">
          <p>
            <span>First Name:</span> {item.firstName}
          </p>
          <p>
            <span>Last Name:</span> {item.lastName}
          </p>
          <p>
            <span>E-mail:</span> {item.email}
          </p>
          <p>
            <span>Phone Number:</span> {item.phone}
          </p>
          <p>
            <span>Address:</span> {item.address}
          </p>
          <p>
            <span>Amount:</span> {item.amount}
          </p>
          <p>
            <span>Token for:</span> {item.tokenFor}
          </p>
          {item.isVerified ? (
            <p className="verified">Verified&nbsp;&#10003;</p>
          ) : (
            <Button onClick={() => handleClick(index, item.ref, item.service, item.amount)}>
              {isLoading === index ? <Loader /> : 'Verify'}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default Tokens;
