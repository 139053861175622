import Admin from '../pages/Admin';
import ContactUs from '../pages/ContactUs';
import ForgotPassword from '../pages/ForgotPassword';
import Inbox from '../pages/Inbox';
import Investment from '../pages/Investment';
import Investments from '../pages/Investments';
import Overview from '../pages/Overview';
import Profile from '../pages/Profile';
import SignIn from '../pages/SignIn';

export const appRoutes = [
  {
    to: '/',
    element: <Investments />,
  },
  {
    to: '/sign-in',
    element: <SignIn />,
  },
  {
    to: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    to: '/progress',
    element: <Overview />,
  },
  {
    to: '/investments/:id',
    element: <Investment />,
  },
  {
    to: '/profile',
    element: <Profile />,
  },
  {
    to: '/inbox',
    element: <Inbox />,
  },
  {
    to: '/contact-us',
    element: <ContactUs />,
  },
  {
    to: '/admin',
    element: <Admin />,
  },
];
