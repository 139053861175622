import ForgotPasswordCard from '../components/ForgotPasswordCard';

const ForgotPassword = () => {
  return (
    <div className="auth">
      <div className="card-wrapper">
        <ForgotPasswordCard />
      </div>
    </div>
  );
};

export default ForgotPassword;
