const Select = ({ label, error, touched, options, placeholder, propertyName, ...others }) => {
  return (
    <div className="select-component">
      <p className="label">{label}</p>

      <select {...others}>
        <option key={99999} value="">
          {placeholder}
        </option>
        {options.map((option) => {
          const value = typeof option === 'string' ? option : option[propertyName] || option.value;
          const name = typeof option === 'string' ? option : option.name;
          return (
            <option key={value} value={value}>
              {name}
            </option>
          );
        })}
      </select>
      <div className="error">{error && touched ? error : ''}</div>
    </div>
  );
};

export default Select;
