import { useEffect, useState } from 'react';
import axios from '../api/axios';
import urls from '../api/urls';
import back from '../assets/svg/arrow-left.svg';
import logo from '../assets/svg/logo-dark.svg';
import Loader from '../components/Loader';
import { useGlobalContext } from '../context/context';

const Inbox = () => {
  const [messages, setMessages] = useState(null);
  const [message, setMessage] = useState(null);

  const {
    state: { user },
  } = useGlobalContext();

  useEffect(() => {
    const getReports = () => {
      axios
        .get(urls.reports.baseUrl, { params: { investorID: user._id } })
        .then((res) => setMessages(res.data.data.report));
    };
    getReports();
  }, [user._id]);

  const handleClick = async (msg) => {
    setMessage(msg);
    if (msg._id === false) {
      axios.patch(urls.reports.markRead, { params: { id: msg._id } });
    }
  };

  if (!messages) {
    return (
      <div className="loader-wrapper">
        <Loader size={50} />
      </div>
    );
  }

  if (!messages?.length) {
    return (
      <div className="inbox">
        <h1>Inbox</h1>

        <div className="body">
          <p className="nothing"> No new messages</p>
        </div>
      </div>
    );
  }

  if (message) {
    return (
      <div className="inbox">
        <h1>Inbox</h1>

        <div className="msg-wrapper">
          <div className="title-wrapper">
            <div onClick={() => setMessage(null)}>
              <img src={back} alt="" />
            </div>
            <p className="title">{message.title}</p>
          </div>
          <p className="content">{message.content}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="inbox">
      <h1>Inbox</h1>

      {!messages.length ? (
        <div className="body">
          <p className="nothing"> No new messages</p>
        </div>
      ) : (
        <div className="messages">
          {messages.map((msg) => (
            <div key={msg._id} className="message" onClick={() => handleClick(msg)}>
              <div className="from">
                <img src={logo} alt="" />
                <p style={{ fontWeight: msg.read ? 400 : 500 }}>CSPillai Team</p>
              </div>

              <div className="title">
                <p style={{ fontWeight: msg.read ? 400 : 500 }}>{msg.title}</p>
              </div>

              <div className="date">
                <p style={{ fontWeight: msg.read ? 400 : 500 }}>{msg.date.slice(0, 21)}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Inbox;
