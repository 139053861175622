export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const getDate = (string) => {
  const date = new Date(string);
  let day = date.getDate();
  let month = months[date.getMonth()].slice(0, 3);

  return `${month} ${day}, ${date.getFullYear()}`;
};

export const getShortDate = (date) => {
  let month = months[date.getMonth()].slice(0, 3);

  return `${month} ${String(date.getFullYear()).slice(-2)}`;
};

export const getDaysElapsed = (date) => {
  const date1 = date ? new Date(date) : new Date();
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const getStartDate = (date) => {
  return date ? new Date(date).getDate() : 0;
};

export const generateYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const yearsArray = Array.from({ length: currentYear - startYear + 1 }, (_, index) => (startYear + index).toString());
  return yearsArray;
};
