import { useEffect, useState } from 'react';
import Button from './inputs/Button';
import Loader from './Loader';
import axios from '../api/axios';
import urls from '../api/urls';

const Refunds = () => {
  const [refunds, setRefunds] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    getAllRefunds();
  }, []);

  const getAllRefunds = async () => {
    await axios.get(urls.refunds.getAll).then((res) => setRefunds(res.data.data.refunds));
  };

  const handleClick = async (index, id) => {
    setIsLoading(index);
    await axios.get(`${urls.refunds.approve}/${id}`).then(() => {
      setIsLoading(null);
      getAllRefunds();
    });
  };

  if (!refunds) {
    return (
      <div className="loader-wrapper">
        <Loader size={50} />
      </div>
    );
  }

  if (!refunds.length) {
    return (
      <div className="refunds">
        <p>No refunds to display</p>
      </div>
    );
  }

  return (
    <div className="refunds">
      {refunds?.map((refund, index) => (
        <div key={refund._id} className="refund">
          <p>
            <span>First Name:</span> {refund.firstName}
          </p>
          <p>
            <span>Last Name:</span> {refund.lastName}
          </p>
          <p>
            <span>E-mail:</span> {refund.email}
          </p>
          <p>
            <span>Reason for refund request:</span> {refund.reason}
          </p>
          {refund.approved ? (
            <p className="approved">Approved&nbsp;&#10003;</p>
          ) : (
            <Button onClick={() => handleClick(index, refund._id)}>
              {isLoading === index ? <Loader /> : 'Verify'}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default Refunds;
