import { useEffect, useState } from 'react';
import { AreaChart as AC, Area, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const AreaChart = ({ values }) => {
  const [data, setData] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (Array.isArray(values)) {
      setData(values);
    }
  }, [values]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsHidden((prev) => !prev);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const maxMetricValue = data?.length ? Math.max(...data.map((obj) => obj.metricValue)) : 10000;

  return (
    <div className="area-chart">
      <div className="heading">
        <h3>Product Pre-orders</h3>
        <p>Overview of last month</p>
      </div>

      <ResponsiveContainer width="100%" height="100%" className={data.length ? '' : 'blur'}>
        <AC
          width={500}
          height={400}
          data={data.length ? data : dummyData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="10" vertical={false} y={250} />
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <YAxis tickLine={false} axisLine={false} domain={[0, maxMetricValue]} />
          <Tooltip />
          <Area type="monotone" dataKey="metricValue" stroke="#8F6FF5" fill="#ece8fd" hide={isHidden} />
          {!data.length && <Area type="monotone" dataKey="uv" stroke="#22C6AB" fill="#109cf133" hide={isHidden} />}
        </AC>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChart;

const dummyData = [
  {
    name: '1',
    uv: 200,
    metricValue: 500,
  },
  {
    name: '5',
    uv: 2000,
    metricValue: 2500,
  },
  {
    name: '10',
    uv: 3400,
    metricValue: 2600,
  },
  {
    name: '15',
    uv: 5500,
    metricValue: 5500,
  },
  {
    name: '20',
    uv: 8000,
    metricValue: 9000,
  },
  {
    name: '25',
    uv: 9500,
    metricValue: 9200,
  },
  {
    name: '30',
    uv: 10000,
    metricValue: 10000,
  },
];
